<template>
  <div>
    <table
      class="ui very basic table"
      aria-describedby="Table des données du signalement"
    >
      <tbody>
        <tr v-if="featureType">
          <td>
            <strong> Type de signalement </strong>
          </td>
          <td>
            <FeatureTypeLink :feature-type="featureType" />
          </td>
        </tr>
        <tr
          v-for="(field, index) in currentFeature.feature_data"
          :key="'field' + index"
        >
          <td>
            <strong
              :class="{ required: isRequired(field) }"
            >
              {{ field.label }}
            </strong>
          </td>
          <td>
            <strong class="ui form">
              <span
                v-if="fastEditionMode && canEditFeature && extra_forms.length > 0"
                :id="field.label"
              >
                <FeatureExtraForm
                  ref="extraForm"
                  :field="getExtraForm(field)"
                />
              </span>
              <i
                v-else-if="field.field_type === 'boolean'"
                :class="[
                  'icon',
                  field.value ? 'olive check' : 'grey times',
                ]"
                aria-hidden="true"
              />
              <span v-else-if="field.value && field.field_type === 'multi_choices_list'">
                {{ field.value.join(', ') }}
              </span>
              <span v-else>
                {{ field.value && field.value.label ? field.value.label : field.value }}
              </span>
            </strong>
          </td>
        </tr>
        <tr>
          <td>
            Auteur
          </td>
          <td>{{ currentFeature.display_creator }}</td>
        </tr>
        <tr>
          <td>
            Statut
          </td>
          <td>
            <i
              v-if="currentFeature.status"
              :class="['icon', statusIcon]"
              aria-hidden="true"
            />
            <FeatureEditStatusField
              v-if="fastEditionMode && canEditFeature && form"
              :status="form.status.value"
              class="inline"
            />
            <span v-else>
              {{ statusLabel }}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            Date de création
          </td>
          <td v-if="currentFeature.created_on">
            {{ currentFeature.created_on | formatDate }}
          </td>
        </tr>
        <tr>
          <td>
            Date de dernière modification
          </td>
          <td v-if="currentFeature.updated_on">
            {{ currentFeature.updated_on | formatDate }}
          </td>
        </tr>
      </tbody>
    </table>

    <h3>Liaison entre signalements</h3>
    <table
      class="ui very basic table"
      aria-describedby="Table des signalements lié à ce signalement"
    >
      <tbody>
        <tr
          v-for="(link, index) in linked_features"
          :key="link.feature_to.title + index"
        >
          <th
            v-if="link.feature_to.feature_type_slug"
            scope="row"
          >
            {{ link.relation_type_display }}
          </th>
          <td
            v-if="link.feature_to.feature_type_slug"
          >
            <FeatureFetchOffsetRoute
              :feature-id="link.feature_to.feature_id"
              :properties="{
                title: link.feature_to.title,
                feature_type: { slug: link.feature_to.feature_type_slug }
              }"
            />
            ({{ link.feature_to.display_creator }} -
            {{ link.feature_to.created_on }})
          </td>
        </tr>
        <tr v-if="linked_features.length === 0">
          <td>
            <em>
              Aucune liaison associée au signalement.
            </em>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import FeatureTypeLink from '@/components/FeatureType/FeatureTypeLink';
import FeatureEditStatusField from '@/components/Feature/FeatureEditStatusField';
import FeatureExtraForm from '@/components/Feature/Edit/FeatureExtraForm';
import FeatureFetchOffsetRoute from '@/components/Feature/FeatureFetchOffsetRoute';

import { statusChoices } from '@/utils';

export default {

  name: 'FeatureTable',
  
  components: {
    FeatureTypeLink,
    FeatureEditStatusField,
    FeatureExtraForm,
    FeatureFetchOffsetRoute,
  },

  filters: {
    formatDate(value) {
      let date = new Date(value);
      date = date.toLocaleString().replace(',', '');
      return date.substr(0, date.length - 3); //* quick & dirty way to remove seconds from date
    },
  },

  props: {
    featureType: {
      type: Object,
      default: () => {},
    },
    fastEditionMode: {
      type: Boolean,
      default: false,
    },
    canEditFeature: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('feature', [
      'currentFeature',
      'linked_features',
      'form',
      'extra_forms',
    ]),

    statusIcon() {
      switch (this.currentFeature.status) {
      case 'archived':
        return 'grey archive';
      case 'pending':
        return 'teal hourglass outline';
      case 'published':
        return 'olive check';
      case 'draft':
        return 'orange pencil alternate';
      default:
        return '';
      }
    },
    statusLabel() {
      const status = statusChoices.find(
        (el) => el.value === this.currentFeature.status
      );
      return status ? status.name : '';
    },
  },

  methods: {
    toFeature(link) {
      this.$emit('tofeature', {
        name: 'details-signalement',
        params: {
          slug_type_signal: link.feature_to.feature_type_slug,
          slug_signal: link.feature_to.feature_id,
        },
      });
    },

    getExtraForm(field) {
      return this.extra_forms.find(exF => exF.label === field.label);
    },

    isRequired(field) {
      const xtraForm = this.getExtraForm(field);
      return xtraForm && xtraForm.is_mandatory;
    }
  }

};
</script>

<style lang="less" scoped>

td {
  strong.required:after {
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #ee2e24;
  }
}

</style>
