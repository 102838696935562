var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"feature-detail"}},[(_vm.currentFeature)?_c('div',{staticClass:"ui grid stackable"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"sixteen wide column"},[(_vm.project)?_c('FeatureHeader',{attrs:{"features-count":_vm.featuresCount,"slug-signal":_vm.slugSignal,"feature-type":_vm.featureType,"fast-edition-mode":_vm.project.fast_edition_mode,"is-feature-creator":_vm.isFeatureCreator,"can-edit-feature":_vm.canEditFeature,"can-delete-feature":_vm.canDeleteFeature},on:{"fastEditFeature":_vm.validateFastEdition,"setIsDeleting":function($event){_vm.isDeleting = true},"tofeature":_vm.pushNgo}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"eight wide column"},[(_vm.project)?_c('FeatureTable',{ref:"featureTable",attrs:{"feature-type":_vm.featureType,"fast-edition-mode":_vm.project.fast_edition_mode,"can-edit-feature":_vm.canEditFeature},on:{"tofeature":_vm.pushNgo}}):_vm._e()],1),_c('div',{staticClass:"eight wide column"},[_c('div',{staticClass:"map-container"},[_c('div',{ref:"map",attrs:{"id":"map"}}),(_vm.basemaps && _vm.map)?_c('SidebarLayers',{ref:"sidebar"}):_vm._e(),_vm._m(0)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"eight wide column"},[_c('FeatureAttachements',{attrs:{"attachments":_vm.attachments}})],1),_c('div',{staticClass:"eight wide column"},[_c('FeatureComments',{attrs:{"events":_vm.events},on:{"fetchEvents":_vm.getFeatureEvents}})],1)]),(_vm.isDeleting)?_c('div',{staticClass:"ui dimmer modals visible active"},[_c('div',{class:[
          'ui mini modal',
          { 'active visible': _vm.isDeleting },
        ]},[_c('i',{staticClass:"close icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.isDeleting = false}}}),(_vm.isDeleting)?_c('div',{staticClass:"ui icon header"},[_c('i',{staticClass:"trash alternate icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Supprimer le signalement ")]):_vm._e(),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"ui red compact fluid button",attrs:{"type":"button"},on:{"click":_vm.deleteFeature}},[_vm._v(" Confirmer la suppression ")])])])]):_vm._e(),(_vm.isLeaving)?_c('div',{staticClass:"ui dimmer modals visible active"},[_c('div',{class:[
          'ui mini modal',
          { 'active visible': _vm.isLeaving },
        ]},[_c('i',{staticClass:"close icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.isLeaving = false}}}),_vm._m(1),_c('div',{staticClass:"content"},[_vm._v(" Les modifications apportées au signalement ne seront pas sauvegardées, continuer ? ")]),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"ui green compact button",attrs:{"type":"button"},on:{"click":_vm.stayOnPage}},[_c('i',{staticClass:"close icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Annuler ")]),_c('button',{staticClass:"ui red compact button",attrs:{"type":"button"},on:{"click":_vm.leavePage}},[_vm._v(" Continuer "),_c('i',{staticClass:"arrow right icon",attrs:{"aria-hidden":"true"}})])])])]):_vm._e()]):_c('div',[_vm._v(" Pas de signalement correspondant trouvé ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ol-popup",attrs:{"id":"popup"}},[_c('a',{staticClass:"ol-popup-closer",attrs:{"id":"popup-closer","href":"#"}}),_c('div',{attrs:{"id":"popup-content"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui icon header"},[_c('i',{staticClass:"sign-out icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Abandonner les modifications ")])
}]

export { render, staticRenderFns }