<template>
  <div
    id="status"
    class="field"
  >
    <Dropdown
      v-if="selectedStatus"
      :options="allowedStatusChoices"
      :selected="selectedStatus.name"
      :selection.sync="selectedStatus"
    />
  </div>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue';
import { statusChoices, allowedStatus2change } from '@/utils';
import { mapState } from 'vuex';


export default {
  name: 'FeatureEditStatusField',
  
  components: {
    Dropdown,
  },

  props: {
    status: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState([
      'user',
      'USER_LEVEL_PROJECTS',
    ]),
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature', [
      'currentFeature'
    ]),
    statusObject() {
      return statusChoices.find((key) => key.value === this.status);
    },

    selectedStatus: {
      get() {
        return this.statusObject;
      },
      set(newValue) {
        this.$store.commit('feature/UPDATE_FORM_FIELD', { name: 'status', value: newValue.value });
      },
    },

    allowedStatusChoices() {
      if (this.project && this.currentFeature && this.user) {
        const isModerate = this.project.moderation;
        const userStatus = this.USER_LEVEL_PROJECTS && this.USER_LEVEL_PROJECTS[this.project.slug];
        const isOwnFeature = this.currentFeature.creator === this.user.id; //* si le contributeur est l'auteur du signalement
        return allowedStatus2change(this.user, isModerate, userStatus, isOwnFeature, /* this.currentRouteName */);
      }
      return [];
    },
  },
};
</script>